import React, { useEffect, useRef, useState } from "react";
import "./app.css";

import { makeStyles } from "@material-ui/core/styles";

import { BrowserRouter as Router, Link, useParams } from "react-router-dom";

import { Redirect, Route, Switch } from "react-router-dom";

import { CircularProgress, Box, Grid, Button, IconButton, Typography, Toolbar, CssBaseline, AppBar, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import clsx from "clsx";

import MainContent from "./MainContent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp, faDownload, faFile } from "@fortawesome/free-solid-svg-icons";
import { useAPI, ApiProvider } from "./api/api-context";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    appBar: {
        backgroundColor: '#222222',
        color: 'white',
        borderBottom: 'solid 1px #22DDFF',
    },
    appBarShift: {
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 2,
        padding: theme.spacing(3),
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginBottom: 0,
    },
}));

export default function App() {
    return (
        <>
            <ApiProvider>
                <Router>
                    <Switch>
                        <Route path={["/home/:file?", "/:file?", "/home/", "/"]}>
                            <AppShell />
                        </Route>
                    </Switch>
                </Router>
            </ApiProvider>
        </>
    );
};

function AppShell() {
    const { file } =  useParams();

    const { Api } = useAPI();
    const [title, setTitle] = useState("SYW Downloads");
    const classes = useStyles();

    const breakpoint = 800;
    const [mobileScreen, setMobileScreen] = useState(window.innerWidth < breakpoint);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setMobileScreen(window.innerWidth < breakpoint);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setMobileScreen(window.innerWidth < breakpoint);
            });
        };
    });

    const [sort, setSort] = useState("UploadDate (newest - oldest)");
    const [nameFilter, setNameFilter] = useState("");

    const [filteredRows, setFilteredRows] = useState([]);

    const columns = [];
    if (!mobileScreen) columns.push({ id: 'index', label: '', align: 'left', minWidth: '5%' });
    if (!mobileScreen) columns.push({ id: 'datestr', label: 'Date', align: 'left', minWidth: '15%' });
    columns.push({ id: 'name', label: 'Name', align: 'left', minWidth: '30%' });
    columns.push({ id: 'size', label: 'Size', align: 'right', minWidth: '20%' });
    columns.push({ id: 'downloadButton', label: '', align: 'left', minWidth: '20%' });
    if (!mobileScreen) columns.push({ id: 'options', label: '', align: 'right', minWidth: '10%' });

    const [rows, setRows] = useState([]);

    const [optionMenuOpen, setOptionMenuOpen] = useState(false);
    const [optionMenuData, setOptionMenuData] = useState({});

    const [errorMsg, setError] = useState(null);

    useEffect(() => {
        Api.GetAvailableFiles().then((downloadableFiles = []) =>
        {
            var rowData = downloadableFiles.map((downloadableFile, index) => {

                let date = `${downloadableFile.Date.getDate()  < 10 ? "0" + downloadableFile.Date.getDate() : downloadableFile.Date.getDate()}-${downloadableFile.Date.getMonth() + 1 < 10 ? "0" + (downloadableFile.Date.getMonth() + 1): downloadableFile.Date.getMonth() + 1}-${downloadableFile.Date.getFullYear()} ` +
                    `${downloadableFile.Date.getHours() < 10 ? "0" + downloadableFile.Date.getHours() : downloadableFile.Date.getHours()}:${downloadableFile.Date.getMinutes() < 10 ? "0" + downloadableFile.Date.getMinutes() : downloadableFile.Date.getMinutes()}`;

                var row = {
                    id: downloadableFile.FileName,
                    downloadButton:
                    <Button
                        name="downloadButton"
                        variant="outlined"
                        style={{ color: 'white', backgroundColor: '#2DF', width: '90%', margin: '0.5rem', textTransform: 'none' }}
                        onClick={(e) =>
                        {
                            row.download()
                        }}
                    >
                        Download <FontAwesomeIcon name="Download" icon={faDownload} style={{ marginLeft: '5px' }} />
                    </Button>,
                    date: downloadableFile.Date,
                    datestr: date,
                    name: downloadableFile.Name,
                    size:
                        downloadableFile.SizeInBytes < 800 ? downloadableFile.SizeInBytes + ' Bytes' :
                        downloadableFile.SizeInKb < 800 ? (downloadableFile.SizeInBytes / 1024) + ' Kb' :
                        downloadableFile.SizeInMb < 800 ? (downloadableFile.SizeInKb / 1024) + ' Mb' :
                        downloadableFile.SizeInGb < 10 ? (downloadableFile.SizeInMb / 1024) + ' Gb' :
                        downloadableFile.SizeInGb + ' Gb',
                    fileName: downloadableFile.FileName,
                    filePath: downloadableFile.FilePath,
                    onContextMenu: (e, row) => {
                        setOptionMenuData({ Name: row.name });
                        setOptionMenuOpen(true);
                    },
                    download: () => {
                        let url = Api.DownloadAudioFileUrl(row.fileName);

                        window.open(url);
                    }
                };

                return row;
            });

            setRows(rowData);
        });
    }, [Api, sort]);

    useEffect(() => {
        const filterResults = (data = []) => {
            var results = nameFilter.length > 0 ? data.filter(x => x.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1) : data;
    
            return results.sort((a, b) => {
                if (sort === "UploadDate (newest - oldest)") {
                    if (a.date > b.date) return -1;
                    else if (a.date < b.date) return 1;
                    else return 0;
                }
                else if (sort === "UploadDate (oldest - newest)") {
                    if (a.date < b.date) return -1;
                    else if (a.date > b.date) return 1;
                    else return 0;
                }
                else if (sort == "Name") {
                    if (a.name < b.name) return -1;
                    else if (a.name > b.name) return 1;
                    else return 0;
                }
            });
        };

        setFilteredRows(
            filterResults(rows)
        );
    }, [nameFilter, rows, sort]);

    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: false,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open"
                        onClick={() => {}}
                        edge="start"
                        className={clsx(classes.menuButton)}
                    >
                        <FontAwesomeIcon icon={faFile} />
                    </IconButton>
                    <Grid>
                        <Link style={{ textDecoration: 'none' }} to="/">
                            <Button style={{ color: 'white' }}>
                                <Typography variant="h6" noWrap className={classes.title}>{title}</Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Toolbar>
            </AppBar>

            <MainContent
                Error={errorMsg}
                Api={Api} setTitle={setTitle}
                columns={columns} rows={filteredRows}
                setSort={setSort} sort={sort}
                setNameFilter={setNameFilter} nameFilter={nameFilter}
            />

            <Dialog open={optionMenuOpen} onClose={() => setOptionMenuOpen(false)}>
                <DialogTitle style={{ backgroundColor: '#222222', color: 'white' }}>Options: {optionMenuData.Name}</DialogTitle>
                <DialogContent style={{ backgroundColor: '#222222', color: 'white' }}>
                    <Grid container direction="row">
                        <Grid container direction="row" style={{ marginBottom: '5px' }}>
                            <Button variant="outlined" style={{ backgroundColor: '#555555', color: 'white' }}
                                onClick={() => {
                                    var row = rows.find(x => x.name === optionMenuData.Name);

                                    let url = Api.DownloadAudioFileUrl(row.fileName);

                                    window.open(url);
                                }}
                            >Download</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};