export default class DownloadFile
{
    constructor(name, fileName, filePath, date, type, size, sizeInBytes, sizeInKb, sizeInMb, sizeInGb)
    {
        this.Name = name ?? null;
        this.FileName = fileName ?? null;
        this.FilePath = filePath ?? null;
        this.Date = date ? new Date(date) : null;
        this.Type = type ?? null;
        this.Size = size ?? 0;
        this.SizeInBytes = sizeInBytes ?? 0;
        this.SizeInKb = sizeInKb ?? 0;
        this.SizeInMb = sizeInMb ?? 0;
        this.SizeInGb = sizeInGb ?? 0;
    }

    Name = '';
    FileName = '';
    FilePath = '';
    Date = new Date();
    Type = '';
    Size = 0;
    SizeInBytes = 0;
    SizeInKb = 0;
    SizeInMb = 0;
    SizeInGb = 0;
}