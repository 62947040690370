import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function AboutPage({  }) {
    return (
        <Grid style={{  }}>
            <Typography style={{ color: 'white' }}>Download some (now) public files I wanted to share with you.</Typography>
            <Link to="/home/index" style={{ textDecoration: 'none' }}>
                <Button variant="outlined" style={{ marginTop: '10px', color: 'white' }}>
                    <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} /> Back to SYW Downloads
                </Button>
            </Link>
        </Grid>
    );
};