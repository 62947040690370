import axios from "axios";
import DownloadFile from "../models/DownloadFile";

export default class Api
{
    constructor()
    {
        this.ServerUrl = 'https://downloadsapi.sywapps.com';
        this.ApiUrl = this.ServerUrl + '/api';
    }

    async GetAvailableFiles()
    {
        var res = await axios.get(this.ApiUrl + '/downloadables/available');

        var files = res.data && res.data.map ? res.data.map(item =>
            new DownloadFile(item.Name, item.FileName, item.FilePath, new Date(item.Date), item.Type, item.Size, item.SizeInBytes, item.SizeInKb, item.SizeInMb, item.SizeInGb)) : [];

        return files;
    }

    DownloadAudioFileUrl(fileName)
    {
        return this.ApiUrl + '/Downloader/Download/' + encodeURIComponent(fileName).replace('#', '%23').replace('/', '%2f').replace('+', '%2B');
    }
}